import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Feed from '../components/Feed';
import Page from '../components/Page';
import Pagination from '../components/Pagination';

const IndexTemplate = ({ data, pageContext }) => {
  const {
    title: siteTitle,
    subtitle: siteSubtitle
  } = data.site.siteMetadata;

  const {
    currentPage,
    hasNextPage,
    hasPrevPage,
    prevPagePath,
    nextPagePath
  } = pageContext;

  // Change to Contentful
  // const { edges } = data.allMarkdownRemark;
  const { edges } = data.allContentfulBlogPost;
  const pageTitle = currentPage > 0 ? `Posts - Page ${currentPage} - ${siteTitle}` : siteTitle;

  return (
    <Layout title={pageTitle} description={siteSubtitle}>
      <Sidebar />
      <Page>
        <Feed edges={edges} />
        <Pagination
          prevPagePath={prevPagePath}
          nextPagePath={nextPagePath}
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
        />
      </Page>
    </Layout>
  );
};

// Category slug has to be added by create-pages and on-create-node--I think??
export const query = graphql`
query IndexTemplate($postsLimit: Int!, $postsOffset: Int!) {
  site {
    siteMetadata {
      title
      subtitle
    }
  }
  allContentfulBlogPost(
    limit: $postsLimit,
    skip: $postsOffset,
    sort: { order: DESC, fields: [publishDate] }
  ){
    edges{
      node{
        slug
        title
        publishDate
        category
        description{
          description
        }
        fields{
          categorySlug
        }
      }
    }
  }
}
`;

// export const query = graphql`
//   query IndexTemplate($postsLimit: Int!, $postsOffset: Int!) {
//     site {
//       siteMetadata {
//         title
//         subtitle
//       }
//     }
//     allMarkdownRemark(
//         limit: $postsLimit,
//         skip: $postsOffset,
//         filter: { frontmatter: { template: { eq: "post" }, draft: { ne: true } } },
//         sort: { order: DESC, fields: [frontmatter___date] }
//       ){
//       edges {
//         node {
//           fields {
//             slug
//             categorySlug
//           }
//           frontmatter {
//             title
//             date
//             category
//             description
//           }
//         }
//       }
//     }
//   }
// `;

export default IndexTemplate;
